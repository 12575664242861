import styled, { css } from 'styled-components';
import { Button } from 'react-rainbow-components';
import {
    PURPLE_600,
    GREY_800,
} from '../../constants';

export default styled(Button)`
    letter-spacing: 0.02688rem;
    height: 3rem;
    line-height: 3rem;
    padding: 0 1.5rem;
    font-weight: 400;
    font-size: 0.9375rem;

    ${(props) => props.variant === 'brand' && css`
        :hover {
            background: ${PURPLE_600};
            box-shadow: 0px 12px 6px -8px rgba(0, 0, 0, 0.42);
        }
    `}

    ${(props) => props.variant === 'base' && css`
        padding: 0 1rem;
    `}

    ${(props) => props.variant === 'border' && css`
        color: ${GREY_800};
    `}

    ${(props) => props.size === 'medium' && css`
        font-size: 0.9375rem;
    `}

    ${(props) => props.borderRadius === 'semi-square' && css`
        border-radius: 0.375rem;
    `}

    ${(props) => props.borderRadius === 'semi-rounded' && css`
        border-radius: 0.75rem;
    `}

    ${(props) => props.shaded && css`
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
    `}
`;
