import { UniversalFormProps } from '@rainbow-modules/forms/lib/components/UniversalForm';
import Button from 'components/Button';
import { MessageContext, MessageFormMode } from '../types';
import { Container } from '../styled';
import DrawerHeader from '../drawerHeader';
import Fields from './fields';
import { StyledUniversalForm, FormActions } from './styled';

interface CreateEditMessageFormProps extends UniversalFormProps {
    mode: MessageFormMode;
    context: MessageContext;
    onRequestClose?: () => void;
}

const CreateEditMessageForm = ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    context,
    mode = 'create',
    onRequestClose = () => {},
    onSubmit = () => {},
    ...formProps
}: CreateEditMessageFormProps) => {
    const title = (
        mode === 'create'
            ? 'Create New Frequent Message'
            : 'Edit Frequent Message'
    );

    const subtitle = (
        mode === 'create'
            ? 'Easily save a message you send often. Once saved, you can quickly reuse it in future chats.'
            : 'Easily edit a message you send often. Once saved, you can quickly reuse it in future chats.'
    );

    const updateButtonLabel = (
        mode === 'create'
            ? 'Create'
            : 'Update'
    );

    return (
        <StyledUniversalForm {...formProps} onSubmit={onSubmit}>
            <Container>
                <DrawerHeader
                    title={title}
                    subtitle={subtitle}
                    onRequestClose={onRequestClose}
                />
                <Fields />
                <FormActions>
                    <Button
                        label="Cancel"
                        borderRadius="semi-rounded"
                        variant="outline-brand"
                        size="small"
                        onClick={onRequestClose}
                    />
                    <Button
                        label={updateButtonLabel}
                        borderRadius="semi-rounded"
                        variant="brand"
                        size="small"
                        type="submit"
                    />
                </FormActions>
            </Container>
        </StyledUniversalForm>
    );
};

export default CreateEditMessageForm;
