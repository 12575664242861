import {
    forwardRef, KeyboardEvent, useCallback, useImperativeHandle, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import ImageIcon from 'components/icons/image';
// import MicrophoneIcon from 'components/icons/microphone';
import SendIcon from 'components/icons/send';
import FrequentMessage from 'components/FrequentMessage';
import HelpMeWrite from 'components/HelpMeWrite';
import { IMAGE_FORMATS_ACCEPTED_STRING } from '../../constants';
import AttachedFiles from './AttachedFiles';
import {
    ChatInputContainer, SendButton, ActionsContainer,
    MessageInput, InputContainer, ActionButton,
    StyledFileInput,
} from './styled';

interface ChatInputProps {
    onTextSend?: (value: string, attachFiles?: File[]) => void;
    isLoading?: boolean;
    disabled?: boolean;
}

const ChatInput = forwardRef(
    ({
        onTextSend = () => {},
        isLoading = false,
        disabled = false,
    }: ChatInputProps, ref) => {
        const { agentId = '' } = useParams();
        const hiddenFileInputRef = useRef<HTMLInputElement>(null);
        const [value, setValue] = useState('');
        const [attachedFiles, setAttachedFiles] = useState<File[]>([]);
        const inputRef = useRef<HTMLTextAreaElement>();

        const handleKeyDown = useCallback(
            (event: KeyboardEvent<HTMLTextAreaElement>) => {
                if (disabled) return;
                if (event.key === 'Enter' && !event.shiftKey) {
                    event.preventDefault();
                    if (/\S/.test(value)) {
                        onTextSend(value);
                        setValue('');
                    }
                }
            },
            [disabled, onTextSend, value],
        );

        const sendText = useCallback(
            () => {
                if (disabled) return;
                onTextSend(value, attachedFiles);
                setValue('');
                setAttachedFiles([]);
            },
            [disabled, onTextSend, value, attachedFiles],
        );

        const attachFiles = useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const files = event.target.files || [];
                if (files.length > 0) {
                    setAttachedFiles([
                        ...attachedFiles,
                        ...Array.from(files as FileList),
                    ]);
                    hiddenFileInputRef.current!.value = '';
                }
            },
            [attachedFiles],
        );

        const removeAttachedFile = useCallback(
            (index: number) => setAttachedFiles([
                ...attachedFiles.slice(0, index),
                ...attachedFiles.slice(index + 1),
            ]),
            [attachedFiles],
        );

        useImperativeHandle(ref, () => ({
            focus: () => {
                inputRef.current?.focus();
            },
        }));

        return (
            <ChatInputContainer>
                <InputContainer>
                    <MessageInput
                        variant="shaded"
                        placeholder="Type here..."
                        value={value}
                        grow
                        rows={1}
                        onChange={(event) => setValue(event.target.value)}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        disabled={isLoading || disabled}
                    />
                    <RenderIf isTrue={attachedFiles.length > 0}>
                        <AttachedFiles
                            files={attachedFiles}
                            onRemoveFile={removeAttachedFile}
                        />
                    </RenderIf>
                    <ActionsContainer>
                        <ActionButton
                            tooltip="Attach image"
                            icon={<ImageIcon />}
                            onClick={() => hiddenFileInputRef.current?.click()}
                            disabled={isLoading || disabled}
                        />
                        <StyledFileInput
                            type="file"
                            multiple
                            ref={hiddenFileInputRef}
                            onChange={attachFiles}
                            accept={IMAGE_FORMATS_ACCEPTED_STRING}
                        />
                        {/* <ActionButton
                            borderRadius="semi-square"
                            title="Record Audio"
                            icon={<MicrophoneIcon />}
                            disabled
                        /> */}

                        <FrequentMessage
                            context="messages"
                            onSelectMessage={(message) => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                // eslint-disable-next-line max-len
                                const textarea = inputRef.current.fieldRef.current.textareaRef.current;
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                    window.HTMLTextAreaElement.prototype,
                                    'value',
                                ).set;
                                nativeInputValueSetter?.call(textarea, message);
                                const event = new Event('input', { bubbles: true });
                                textarea.dispatchEvent(event);
                                setTimeout(() => inputRef.current?.focus(), 0);
                            }}
                            agentId={agentId}
                        />
                        <RenderIf isTrue={!isEmpty(value)}>
                            <HelpMeWrite
                                value={value}
                                onInsert={(text) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    // eslint-disable-next-line max-len
                                    const textarea = inputRef.current.fieldRef.current.textareaRef.current;
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                                        window.HTMLTextAreaElement.prototype,
                                        'value',
                                    ).set;
                                    nativeInputValueSetter?.call(textarea, text);
                                    const event = new Event('input', { bubbles: true });
                                    textarea.dispatchEvent(event);
                                    setTimeout(() => inputRef.current?.focus(), 0);
                                }}
                                agentId={agentId}
                            />
                        </RenderIf>
                    </ActionsContainer>
                </InputContainer>
                <SendButton
                    variant="brand"
                    icon={<SendIcon />}
                    onClick={sendText}
                    disabled={!(value.trim() || attachedFiles.length) || disabled}
                />
            </ChatInputContainer>
        );
    },
);

export default ChatInput;
