import { useState } from 'react';
import { RenderIf } from 'react-rainbow-components';
import { isEmpty } from '@rainbow-modules/validation';
import ArrowRight from 'components/icons/arrowRight';
import {
    ArrowButton,
    InputContainer,
    StyledDiamondsIcon,
    StyledTextarea,
} from './styled';

interface InstructionInputProps {
    disabled?: boolean;
    onSubmit?: (instruction: string) => void;
}

const InstructionInput = ({
    disabled = false,
    onSubmit = () => {},
}: InstructionInputProps) => {
    const [instruction, setInstruction] = useState<string>('');
    return (
        <InputContainer>
            <StyledTextarea
                label="Custom"
                hideLabel
                placeholder={'Describe your change, you can try "Make professional"'}
                borderRadius="semi-rounded"
                grow
                rows={1}
                value={instruction}
                onChange={(e) => setInstruction(e.target.value)}
                disabled={disabled}
            />
            <StyledDiamondsIcon />
            <RenderIf isTrue={!isEmpty(instruction)}>
                <ArrowButton
                    variant="brand"
                    icon={<ArrowRight />}
                    onClick={() => onSubmit(instruction)}
                    disabled={disabled}
                    borderRadius="semi-rounded"
                />
            </RenderIf>
        </InputContainer>
    );
};

export default InstructionInput;
