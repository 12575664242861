import { Field } from 'react-final-form';
import { validateNotEmpty } from './validators';
import {
    FieldsContainer,
    StyledInput,
    Row,
    StyledTextarea,
} from './styled';

const Fields = () => (
    <FieldsContainer>
        <Row>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledInput}
                name="title"
                label="Friendly Name"
                labelAlignment="left"
                placeholder="Enter a friendly name for the frequent message"
                borderRadius="semi-rounded"
                autoComplete="off"
                required
                validate={validateNotEmpty('This field is required')}
            />
        </Row>
        <Row>
            <Field
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                component={StyledTextarea}
                name="content"
                label="Content"
                labelAlignment="left"
                placeholder="Enter the content for the frequent message"
                borderRadius="semi-rounded"
                rows={8}
                required
                validate={validateNotEmpty('This field is required')}
            />
        </Row>
    </FieldsContainer>
);

export default Fields;
